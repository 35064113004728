import { EDIT_API_ENDPOINTS, GET_API_ENDPOINTS } from "../config/apiConfig";
import HttpClient from "../helpers/HttpClient";

class AboutUsService {
  // POST request to fetch About Us page section wise
  async getAboutUsSection(payload) {
    const response = await HttpClient.post(GET_API_ENDPOINTS.SECTIONWISE, {
      Page: "AboutUs",
      Section: payload.sectionName,
    });
    return response;
  }

  // POST request edit brochure section
  async editBrochure(payload) {
    const response = await HttpClient.post(EDIT_API_ENDPOINTS.EDIT_BROCHURE, {
      brochures: payload,
    });
    return response;
  }

  // POST request to create AboutUsSection
  async editAboutUsSection(payload) {
    let newHeaders;
    if (payload?.isMultipart) {
      newHeaders = {
        "Content-Type": "multipart/form-data",
      };
    }

    const response = await HttpClient.post(
      `${EDIT_API_ENDPOINTS.ABOUTUS}/${payload.sectionName}`,
      payload?.isMultipart ? payload.sectionData : { ...payload.sectionData },
      newHeaders && { ...newHeaders }
    );
    return response;
  }
}

const aboutUsService = new AboutUsService();
export default aboutUsService;
