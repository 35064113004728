// Keep all the api related configs such as endpoints in this file
const baseUrl = "https://isourse.com:1047/"; //production api's
// const baseUrl = "https://demo.isoping.com:7344"; //test api's
// const baseUrl = "https://192.168.222.40:6642"; //development api's

// Endpoints for getting cms data
export const GET_API_ENDPOINTS = {
  SECTIONWISE: `${baseUrl}/GetSectionWise`,
  PAGEWISE: `${baseUrl}/GetPageData`,
  GET_AREAS: `${baseUrl}/ContactUs/Area/Get`,
  GET_PDF: `${baseUrl}/pdf/getAllpdf`,
};

// Endpoints for editing cms data
export const EDIT_API_ENDPOINTS = {
  HOME: `${baseUrl}/Home`,
  ABOUTUS: `${baseUrl}/AboutUs`,
  SERVICES: `${baseUrl}/Services`,
  CAREERS: `${baseUrl}/Careers`,
  THEMES: `${baseUrl}/Themes`,
  PROJECTS: `${baseUrl}/Projects`,
  FAQ: `${baseUrl}/Faq`,
  TERMSOFUSE: `${baseUrl}/TermsOfUse`,
  PRIVACY_POLICY: `${baseUrl}/PrivacyPolicy`,
  CONTACT_US: `${baseUrl}/ContactUs`,
  BLOGS: `${baseUrl}/Blogs`,
  IMAGES: `${baseUrl}/Images`,
  PDF_UPLOAD: `${baseUrl}/pdf/UploadPdf`,
  EDIT_BROCHURE: `${baseUrl}/pdf/uploadBrochureInDB`,
  SITE_INFO: `${baseUrl}/SiteInfo`,
};

// Endpoints for login of user
export const LOGIN_API_ENDPOINTS = {
  VERIFY_EMAIL: `${baseUrl}/Login/EnterEmail`,
  USER_LOGIN: `${baseUrl}/Login`,
};
