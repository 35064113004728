import { createSlice } from "@reduxjs/toolkit";

// Initial state for aboutUs slice
const initialState = {
  metaSection: {},
  section1: {},
  section2: {},
  section3: {},
  section4: {},
  section5: {},
  section6: {},
};

const aboutUsSlice = createSlice({
  name: "aboutUs",
  initialState,
  reducers: {
    setAboutUsSection: (state, action) => {
      const { payload } = action;
      state[payload.sectionName] = payload.data;
    },
  },
});

export const aboutUsActions = aboutUsSlice.actions;
export const aboutUsReducer = aboutUsSlice.reducer;

export const AboutUsActionTypes = {
  FETCH_ABOUT_US: "AboutUs/Fetch",
  EDIT_ABOUT_US: "AboutUs/Edit",
};
